<template>
    <v-app>
        <v-app-bar
                app dark color="green darken-4" elevation="2"
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-app-bar-title>Phongsavanh Insurance</v-app-bar-title>



            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text plain :ripple="false" exact to="/client"> Dashboard</v-btn>
                <v-btn text plain :ripple="false" to="/client/track"> Track</v-btn>
                <v-btn text plain :ripple="false" to="/client/report"> Report</v-btn>
                <v-btn text plain :ripple="false"> Logout</v-btn>
            </v-toolbar-items>


        </v-app-bar
        >
        <v-main>
            <div class="pa-3">
                <router-view/>
            </div>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: "clientLayout",
        data:()=>({
            drawer:null
        })
    }
</script>

<style scoped>

</style>