<template>
    <v-app>
        <v-navigation-drawer
                v-model="drawer"
                app
                dark
                width="200"
                color="#212121"
                :temporary="this.$vuetify.breakpoint.smAndDown"

        >
            <v-list-item >
                <v-list-item-content>
                    <v-list-item-title  class="text-h4">
                        ANS
                    </v-list-item-title>

                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item to="/" dense>
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('MAIN.DASHBOARD')}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list
                dark
                nav
                v-for="(row,index) in items"
                :key="index"
            >
                <v-subheader>
                    {{$t(row.group_tile)}}
                </v-subheader>
                
                <v-list-item
                        v-for="item in row.group_item"
                        :key="$t(item.title)"
                        :to="item.to"
                        dense


                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>

        <v-app-bar app dark color="green darken-4" elevation="2">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{$t('MAIN.COMPANY_NAME')}}</v-toolbar-title>
            <v-spacer></v-spacer>



            <v-btn color="yellow lighten-1" icon>
                <v-icon>mdi-bell-outline</v-icon>
            </v-btn>


            <v-menu v-model="languageMenu" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="text-capitalize" v-bind="attrs" v-on="on" text >
                        <country-flag  :country='activeLang.flag' />
                        <v-icon small right>mdi-menu-down</v-icon>

                    </v-btn>
                </template>
                <v-list >
                    <v-list-item  style="min-height: 20px;min-width: 20px" v-for="(lang, index) in Langs" :key="index" @click="ChangLang(lang)" v-show="lang.value!=activeLang.value">
                        <v-list-item-avatar style="min-height: 20px;min-width: 20px;width: 20px;height: 20px"  class="mr-0"><country-flag  :country='lang.flag' size='small'/></v-list-item-avatar>
                        <v-list-item-content >
                            <v-list-item-title>  {{ lang.title }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>
            </v-menu>

            <v-menu
                    left
                    transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>

                    <v-list-item
                            dense
                            @click="logout"
                    >
                        <v-list-item-title>
                            <v-icon>mdi-logout</v-icon>
                            {{$t("MAIN.LOGOUT")}}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

       <loader-component></loader-component>


        <v-main>

            <div class="pa-3">
                <router-view></router-view>
            </div>

        </v-main>
    </v-app>
</template>

<script>
    import CountryFlag from 'vue-country-flag'
    import LoaderComponent from "@/components/Loader";
    export default ({
        name: 'MainLayout',
        components:{
            CountryFlag,
            LoaderComponent
        },
        data: () => ({
            drawer: null,
            items: [
                {
                    "group_tile":"PRODUCT.HEALTH",
                    "group_item":[
                        {title: 'REPORT.LIST_VIEW', icon: 'mdi-format-list-numbered-rtl', to: '/health-list'},
                        {title: 'REPORT.AGE_ANS', icon: 'mdi-clipboard-text-clock', to: '/health-age'},
                        {title: 'REPORT.GENDER_ANS', icon: 'mdi-gender-male-female', to: '/health-gender'},
                        {title: 'REPORT.PRODUCT_ANS', icon: 'mdi-chart-bell-curve-cumulative', to: '/health-cover'},
                        {title: 'REPORT.HOSPITAL', icon: 'mdi-hospital-building', to: '/health-hospital'},
                        {title: 'REPORT.DETAIL_VIEW', icon: 'mdi-text-box-search-outline', to: '/health-detail'},
                    ]

                },
                {
                    "group_tile":"PRODUCT.EZY_LIFE",
                    "group_item":[
                        {title: 'REPORT.CLIENT_ANS', icon: 'mdi-nature-people', to: '/life-age'},
                        {title: 'REPORT.STATUS_ANS', icon: 'mdi-timelapse', to: '/life-policy'},
                        {title: 'REPORT.CHANNEL_ANS', icon: 'mdi-sitemap-outline', to: '/life-channel'},
                        {title: 'REPORT.LIFE_POLICY', icon: 'mdi-finance', to: '/life-export'},
                        {title: 'REPORT.GROW_UP', icon: 'mdi-trending-up', to: '/life-grow'},

                    ]

                },
                // {
                //     "group_tile":"PRODUCT.MOTOR",
                //     "group_item":[
                //         {title: 'REPORT.MT_PLAN', icon: 'mdi-shield-link-variant-outline', to: '/motor-cover'},
                //         {title: 'REPORT.MT_CATEGORY', icon: 'mdi-shape', to: '/motor-category'},
                //         {title: 'REPORT.CHANNEL_ANS', icon: 'mdi-sitemap-outline', to: '/motor-channel'},
                //
                //
                //     ]
                //
                // }
                // ,
                {
                    "group_tile":"PRODUCT.LEAVE",
                    "group_item":[
                        {title: 'REPORT.ATD_REPORT', icon: 'mdi-account-clock-outline', to: '/leave-all'},
                        // {title: 'REPORT.LEAVE_DETAIL', icon: 'mdi-file-sign', to: '/leave-detail'}

                    ]

                }

            ],

            languageMenu: false,
        }),
        computed:{
            activeLang(){
                let lang= this.$store.getters.getLang;
                if(lang=='la'){
                    return {value:'la',flag:'la'}
                }else{
                    return {value:'en',flag:'us'}
                }
            },
            Langs(){
                return [
                    { title: 'ພາສາລາວ',value:'la',flag: 'la' },
                    { title: 'English',value:'en',flag:'us' },
                ]
            }

        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout')

            },
            ChangLang(lang) {

                // this.activeLang = lang.title
                // this.activeFlag=lang.flag
                this.$i18n.locale=lang.value
                this.$store.dispatch('setLang',lang.value)

            },

        },


    })
</script>

<style scoped>
    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 15px;
    }
    .v-list-item--active {
        background-color: #f0b41d;

    }
    .theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
        opacity: 0.1;

    }
</style>
