<template>
    <div class="home">
        <v-card>
            <v-card-title>
                Health Insurance
            </v-card-title>

            <v-card-text>
<!--                <v-row>-->
<!--                    <v-col cols="12" lg="2" md="3" sm="4">-->
<!--                        <v-btn @click="decrement">-->
<!--                            <v-icon>mdi-chevron-left</v-icon>-->
<!--                        </v-btn>-->

<!--                        <v-btn @click="Increment">-->
<!--                            <v-icon>mdi-chevron-right</v-icon>-->
<!--                        </v-btn>-->
<!--                    </v-col>-->
<!--                </v-row>-->
                <v-row>
                    <v-col cols="12">
                        <bar-chart v-show="true" v-if="chart_data_loaded" :chart-data="chartData" :height="600"></bar-chart>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>

    </div>
</template>

<script>

    import BarChart from "@/components/BarChart";


    export default {
        name: 'HomeView',
        components: { BarChart},
        data: () => ({
            chart_data_loaded: true,
            month:(new Date()).getMonth(),
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                ],

                datasets: [
                    {
                        label: 'NET PREMIUM',
                        backgroundColor: ["#56d798"],
                        data: [0, 0, 0],
                        lineTension: 0.2,
                    },
                    {
                        label: 'CLAIM AMOUNT',
                        backgroundColor: ["#f38b4a"],
                        data: [0, 0, 0],
                        lineTension: 0.2,
                    },
                    {
                        label: 'PAID AMOUNT',
                        backgroundColor: ["#E57373"],
                        data: [0, 0, 0],
                        lineTension: 0.2,
                    }
                ]
            },

        }),
        watch:{
            month(){
                this.getData()
            }
        },

        mounted() {
            this.getData()
        },
        methods: {
            getData:async function() {
                try {

                    this.chart_data_loaded=false
                    let res = await window.axios.get('/home', {params: {month:this.month}})
                    const {label,sale_data,claim_data,paid_data}=res.data

                     // this.$set(this.chartData.labels, 0, label);
                    for(let i=0;i<label.length;i++){
                        this.$set(this.chartData.labels, i, label[i]);
                        this.$set( this.chartData.datasets[0].data, i, sale_data[i]);
                        this.$set( this.chartData.datasets[1].data, i, claim_data[i]);
                        this.$set( this.chartData.datasets[2].data, i, paid_data[i]);

                    }
                    // this.chartData.labels=label
                    // this.chartData.datasets[0].data=sale_data
                    // this.chartData.datasets[1].data=claim_data
                    // this.chartData.datasets[2].data=paid_data
                    this.chart_data_loaded=true

                } catch (e) {
                    console.log(e)
                }
            },
            Increment(){
                if(this.month<12){
                    this.month+=1;
                }else {
                    this.month=3
                }

            },
            decrement(){
                if(this.month>3){
                    this.month-=1;
                }else {
                    this.month=12
                }
            }
        }


    }
</script>
