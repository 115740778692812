<template>
    <Bar
            :options="chartOptions"
            :data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
    />
</template>

<script>
    import { Bar } from 'vue-chartjs'
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        BarElement,
        CategoryScale,
        LinearScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ChartDataLabels)

    export default {
        name: 'BarChart',
        components: {
            Bar
        },
        props: {
            chartId: {
                type: String,
                default: 'bar-chart'
            },
            datasetIdKey: {
                type: String,
                default: 'label'
            },
            width: {
                type: Number,
                default: 400
            },
            height: {
                type: Number,
                default: 400
            },
            cssClasses: {
                default: '',
                type: String
            },
            styles: {
                type: Object,
                default: () => {}
            },
            plugins: {
                type: Array,
                default: () => []
            },
            chartData: {
                type:Object,
                required:true
            },
        },

        data() {
            return {

                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top'
                        },
                        datalabels: {
                            // color: "black",
                            anchor: 'middle',
                            align: 'top',
                            display: 'true',
                            font: {
                                weight: "bold",
                                size: 10
                            },
                            formatter: function(value,context) {
                                var index = context.dataIndex
                                var previousValue = context.dataset.data[index - 1] || context.dataset.data[index ]
                                var growthPercentage = ((value - previousValue) / previousValue) * 100
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' LAK'+' ('+ growthPercentage.toFixed(0)+'%)';
                                // return  growthPercentage.toFixed(0)+'%';
                                // return value+ ' LAK';
                            }

                        },
                        // tooltip: {
                        //     enabled: true,
                        //     callbacks: {
                        //         label: (tooltipItem) => {
                        //             // console.log(tooltipItem)
                        //
                        //             const current_value =tooltipItem.dataset.data[tooltipItem.dataIndex];
                        //
                        //             const total=tooltipItem.dataset.data.reduce((sum, value) => sum + value, 0);
                        //             const percentage = (current_value / total) * 100;
                        //
                        //             // console.log(total)
                        //
                        //             const number_format=current_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        //             return ` ${number_format} (${percentage.toFixed(2)}%)`;
                        //
                        //         },
                        //     },
                        // },
                    }
                }
            }
        }
    }
</script>
