import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainLayout from "@/views/MainLayout";
import store from '@/store'
import clientLayout from "@/views/clientLayout";

Vue.use(VueRouter)

const routes = [

    //admin section

  {path:'/',component:HomeView,meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-list',component:()=>import('@/views/health/ListView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-age',component:()=>import('@/views/health/AgeView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-gender',component:()=>import('@/views/health/GenderView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-cover',component:()=>import('@/views/health/TypeView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-hospital',component:()=>import('@/views/health/HospitalView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/health-detail',component:()=>import('@/views/health/PolicyView'),meta: {requiresAuth: true,layout:MainLayout}},

  {path:'/health-hospital/detail/:id',component:()=>import('@/views/health/HospitalDetail'),meta: {requiresAuth: true,layout:MainLayout}},

  // EZY LIFE
  {path:'/life-age',component:()=>import('@/views/ezl/AgeView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/life-policy',component:()=>import('@/views/ezl/PolicyView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/life-channel',component:()=>import('@/views/ezl/channelView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/life-export',component:()=>import('@/views/ezl/PolicyList'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/life-grow',component:()=>import('@/views/ezl/growView'),meta: {requiresAuth: true,layout:MainLayout}},

  //leave
  {path:'/leave-all',component:()=>import('@/views/leave/leaveView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/leave-detail',component:()=>import('@/views/leave/detailView'),meta: {requiresAuth: true,layout:MainLayout}},

  //motor
  {path:'/motor-cover',component:()=>import('@/views/motor/optionView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/motor-category',component:()=>import('@/views/motor/categoryView'),meta: {requiresAuth: true,layout:MainLayout}},
  {path:'/motor-channel',component:()=>import('@/views/motor/channelView'),meta: {requiresAuth: true,layout:MainLayout}},

  {path: '/login', name: 'login', component: () => import('../views/LoginView.vue')},



    //client section

  {path:'/client',component:()=>import('@/views/client/boardView'),meta: {requiresAuth: true,layout:clientLayout} },
  {path: '/client/login',component:()=>import('@/views/client/loginView')},

  {path: '/client/board',component:()=>import('@/views/client/boardView'),meta: {requiresAuth: true,layout:clientLayout}},
  {path: '/client/track',component:()=>import('@/views/client/trackView'),meta: {requiresAuth: true,layout:clientLayout}},
  {path: '/client/report',component:()=>import('@/views/client/reportView'),meta: {requiresAuth: true,layout:clientLayout}},




  {
    path: '*',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
